<template>
    <div>
        <!-- 顶部 -->
        <el-row>
            <el-col :span='24'>
                <el-form :inline='true'>
                    <el-form-item label='发布状态：'>
                        <el-select v-model="filters.status" placeholder="请选择" clearable @change='getExamProjectInfoListPage'>
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label='关键字：'>
                        <el-input v-model='filters.keyWord' placeholder='请输入项目名称/编号' clearable @clear='getExamProjectInfoListPage' @keyup.enter.native='getExamProjectInfoListPage' @input='() => (filters.keyWord = filters.keyWord.replace(/\s/g,""))'></el-input>
                    </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <!-- 列表 -->
        <el-table
        :data="tableData"
        border
        v-loading="listLoading"
        highlight-current-row
        @current-change="selectCurrentRow"
        style="width: 95%">
            <el-table-column  type="index"  width="55" label='序号' ></el-table-column>
            <!-- <el-table-column prop="ExamProjectNumber" label="项目编号" width="90" align='center'></el-table-column> -->
            <el-table-column prop="ExamProjectName" label="项目名称" width="" align='center' show-overflow-tooltip></el-table-column>
            <!-- <el-table-column prop="DataDictionaryName" label="数据类型" width="" align='center'> -->
            <!-- <el-table-column prop="DataDictionaryID" label="数据类型" width="" align='center'>     -->
            </el-table-column>
            <el-table-column prop="ExamProjectNotes" label="项目简介" width="" align='center' show-overflow-tooltip></el-table-column>
            <el-table-column prop="ExamProcedure" label="考试流程" width="" align='center' show-overflow-tooltip></el-table-column>
            <el-table-column prop="ExamPaperName" label="试卷名称" width="" align='center' show-overflow-tooltip></el-table-column>
            <el-table-column prop="Status" label="发布状态" width="" align='center'>
                <template slot-scope='scope'>
                    <el-tag :type='scope.row.Status == 0?"primary":scope.row.Status == 1?"warning":"success"'>
                        {{scope.row.Status == 0?"未发布":scope.row.Status == 1?"发布中":"已发布"}}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="CreateUserID" label="创建人" width="90" align='center'></el-table-column>
            <el-table-column prop="CreateTime" label="创建时间" width="" align='center' :formatter="formatCreateTime" show-overflow-tooltip></el-table-column>
        </el-table>
        <!-- 新增/编辑/发布 -->
        <el-dialog
        :title="title == 1?'新增':title == 2?'编辑':'发布'"
        :visible.sync="dialogVisible"
        width="50%"
        :close-on-click-modal='false'
        @close='dialogClose'
        >
        <el-form :model="form" :rules="rules" ref="formRef" label-width="130px" class="demo-ruleForm" :disabled='title == 3?true:false'>
            <el-form-item label="项目编号：" prop="examProjectNumber">
                <el-input v-model="form.examProjectNumber"></el-input>
            </el-form-item>
            <el-form-item label="项目名称：" prop="examProjectName">
                <el-input v-model="form.examProjectName"></el-input>
            </el-form-item>
            <el-form-item label="项目介绍：" prop="examProjectNotes">
                <el-input v-model="form.examProjectNotes" type='textarea' placeholder='请输入项目介绍'></el-input>
            </el-form-item>
            <el-form-item label="考试流程：" prop="examProcedure">
                <el-input v-model="form.examProcedure" type='textarea' placeholder='请输入考试流程'></el-input>
            </el-form-item>
        </el-form>
        <div v-if='title == 3'>
            <el-divider></el-divider>
            <el-form :model="publicForm" :rules="publicRules" ref="publicFormRef" label-width="130px" class="demo-ruleForm">
                <el-form-item label='试卷名称：' prop='examPaperInfoID'>
                    <el-select v-model="publicForm.examPaperInfoID" placeholder="请选择" clearable @change='getExamProjectInfoListPage'>
                        <template>
                            <el-option
                                v-for="item in options1"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </template>
                        <!-- 分页 -->
                        <el-pagination
                            :hide-on-single-page="isHidden"
                            :total="pagesForExamPaper.dataCount"
                            layout="prev, pager, next"
                            @current-change="handleCurrentChangeForExamPaper">
                        </el-pagination>
                    </el-select>
                </el-form-item>
                <el-form-item label='审批记录名称：' prop='approvalRecord.recordName'>
                    <el-input v-model='publicForm.approvalRecord.recordName' @input='() =>(publicForm.approvalRecord.recordName = publicForm.approvalRecord.recordName.replace(/\s/g,""))'></el-input>
                </el-form-item>
                <el-form-item label='审批流程：' prop='approvalRecord.approvalProcessID'>
                    <el-select v-model="publicForm.approvalRecord.approvalProcessID" placeholder="请选择" clearable @change='getExamProjectInfoListPage'>
                        <el-option
                            v-for="item in options2"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label='描述：' prop='approvalRecord.remarks'>
                    <el-input v-model='publicForm.approvalRecord.remarks' type='textarea' placeholder='请输入描述'></el-input>
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click='formSubmit' :loading='dialogLoading'>确 定</el-button>
        </span>
        </el-dialog>
        <!-- 分页 -->
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pages.pageIndex"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pages.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pages.dataCount">
        </el-pagination>
    </div>
</template>

<script>
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import util from "../../../util/date";
import Qs from 'qs'
import {
    getExamProjectInfoListPage,
    addExamProjectInfo,
    updateExamProjectInfo,
    deleteExamProjectInfo,
    publishExamProjectInfo,
    CancelExamProjectInfo,
    getExaminationPaperListPage,
    getApprovalProcessListPage
} from '@/api/api'
export default {
    components: { Toolbar },
    data(){
        return {
            buttonList: [],
            filters:{
                status:'',
                keyWord:''
            },
            options:[
                {label:'未发布',value:0},
                {label:'发布中',value:1},
                {label:'已发布',value:2}
            ],
            title:1, // 新增、编辑、发布
            dialogVisible:false, // 新增、编辑的显示隐藏
            dialogLoading:false, // 表单加载
            tableData:[], // 列表数据
            listLoading:false, // 列表加载
            currentRow:{},
            // 表单数据
            form:{
                examProjectNumber:'',
                examProjectName:'',
                examProjectNotes:'',
                examProcedure:'',                
            },
            rules:{
                examProjectNumber:[{required:true,message:'请输入项目编号',trigger:'bulr'}],
                examProjectName:[{required:true,message:'请输入项目名称',trigger:'blur'}],
                examProjectNotes:[{}],
                examProcedure:[{}],
            },
            pages:{
                pageIndex:1,
                pageSize:20,
                dataCount:0
            },
            // 发布的表单数据
            publicForm:{
                examPaperInfoID:'',
                approvalRecord:{
                    recordName:'',
                    approvalProcessID:'',
                    remarks:''
                }
            },
            publicRules:{
                examPaperInfoID:[{required:true,message:'请选择试卷',trigger:'blur'}],
                'approvalRecord.recordName':[{required:true,message:'请输入审批记录名称',trigger:'blur'}],
                'approvalRecord.approvalProcessID':[{required:true,message:'请选择审批流程',trigger:'blur'}],
                remarks:[{}]
            },
            // 试卷
            options1:[],
            // 审批流程
            options2:[],
            // 试卷分页
            pagesForExamPaper:{
                pageIndex:1,
                dataCount:0
            },
            isHidden:true, // 试卷是单页时隐藏
            // 审批流程分页
            pagesForApprovalProcess:{
                pageIndex:1,
                dataCount:0
            }
        }
    },
    methods:{
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        formatCreateTime: function(row, column) {
            return !row.CreateTime || row.CreateTime == ""
            ? ""
            : util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd hh:mm:ss");
        },
        // 选择列表当前行
        selectCurrentRow(row){
            this.currentRow = row
        },
        // 分页
        handleSizeChange(value){
            this.pages.pageSize = value
            this.getData()
        },
        handleCurrentChange(value){
            window.sessionStorage.setItem('pagination',JSON.stringify(value))
            this.pages.pageIndex = value
            this.getData()
        },
        // 获取列表数据
        getData(){
            var params = {
                projectName:this.filters.keyWord,
                projectNumber:'',
                status:this.filters.status,
                pageIndex:'',
                pageSize:''
            }
            this.listLoading = true
            getExamProjectInfoListPage(params).then(res => {
                this.listLoading = false
                if(res.data.Success){
                    this.tableData = res.data.Response.Data
                    this.pages.dataCount = res.data.Response.DataCount
                }else{
                    this.$message.error(res.data.Message)
                }
            }).catch(() => {this.listLoading = false})
        },
        // 查询
        getExamProjectInfoListPage(){
            this.pages.pageIndex = 1
            this.getData()
        },
        // 新增
        handleAdd(){
            this.title = 1
            this.form = {
                examProjectNumber:'',
                examProjectName:'',
                examProjectNotes:'',
                examProcedure:'', 
            }
            this.dialogVisible = true 
        },
        // 编辑
        handleEdit(){
            var row = this.currentRow
            if(JSON.stringify(row) == '{}'){
                return this.$message.warning('请选择要编辑的一行数据')
            }
            if(row.Status != 0){
                return this.$message.warning("项目正在发布/发布中,无法编辑")
            }
            this.form = {
                examProjectNumber:row.ExamProjectNumber,
                examProjectName:row.ExamProjectName,
                dataDictionaryID:row.DataDictionaryID,
                examProjectNotes:row.ExamProjectNotes,
                examProcedure:row.ExamProcedure,
            }
            this.title = 2
            this.dialogVisible = true
        },
        // 删除
        handleDel(){
            var row = this.currentRow
            if(JSON.stringify(row) == '{}'){
                return this.$message.warning('请选择要删除的一行数据')
            }
            if(row.Status != 0){
                return this.$message.warning('该项目在发布中/已发布，不能删除')
            }
            this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                var params = {id:this.currentRow.ID}
                deleteExamProjectInfo(Qs.stringify(params)).then(res => {
                    if(res.data.Success){
                        this.$message.success(res.data.Message)
                        this.getData()
                    }else{
                        this.$message.error(res.data.Message)
                    }
                }).catch(() => {})
            }).catch(() => {})
        },
        // 发布
        publishExamProjectInfo(){
            var row = this.currentRow
            if(JSON.stringify(row) == '{}'){
                return this.$message.warning('请选择要发布的一行数据')
            }
            if(row.Status == 1){
                return this.$message.warning('该项目正在发布中...')
            }
            if(row.Status == 2){
                return this.$message.warning('该项目已发布...')
            }
            this.title = 3
            this.form = {
                examProjectNumber:row.ExamProjectNumber,
                examProjectName:row.ExamProjectName,
                examProjectNotes:row.ExamProjectNotes,
                examProcedure:row.ExamProcedure,
            }
            this.publicForm = {
                examPaperInfoID:'',
                approvalRecord:{
                    recordName:'',
                    approvalProcessID:'',
                    remarks:''
                }
            }
            this.dialogVisible = true
            this.publicForm.examProjectInfoID = row.ID
            this.getExamPaper()
            this.getApprovalProcess()
        },
        // 取消发布
        handleCancelExamProject(){
            var row = this.currentRow
            if(JSON.stringify(row) == '{}'){
                return this.$message.warning('请选择要取消发布的一行数据')
            }
            if(row.Status == 0){
                return this.$message.warning('该项目未发布')
            }
            if(row.Status == 1){
                return this.$message.warning('该项目正在发布中')
            }
            this.$confirm('确定要取消发布吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                var params = {id:row.ID}
                CancelExamProjectInfo(Qs.stringify(params)).then(res => {
                    if(res.data.Success){
                        this.$message.success(res.data.Message)
                        this.getData()
                    }else{
                        this.$message.error(res.data.Message)
                    }
                }).catch(() => {})
            })
        },
        // 表单提交
        formSubmit(){
            if(this.title != 3){
                this.$refs['formRef'].validate((valid) => {
                    if(valid){
                        var params = {...this.form}
                        this.dialogLoading = true
                        if(this.title == 1){                       
                            this.addSubmit(params)
                        }else if(this.title == 2){
                            this.editSubmit(params)
                        }                 
                    }else{return}
                })
            }else{
                this.$refs['publicFormRef'].validate((valid) => {
                    if(valid){
                        var params = {...this.publicForm}
                        this.dialogLoading = true
                        publishExamProjectInfo(params).then(res => {
                            this.dialogLoading = false
                            this.dialogVisible = false
                            if(res.data.Success){
                                this.$message.success(res.data.Message)
                                this.getData()
                            }else{
                                this.$message.error(res.data.Message)
                            }
                        }).catch(() => {
                            this.dialogLoading = false
                            this.dialogVisible = false
                        })
                    }
                })
            }            
        },
        addSubmit(params){
            addExamProjectInfo(params).then(res => {
                this.dialogLoading = false
                this.dialogVisible = false
                if(res.data.Success){
                    this.$message.success(res.data.Message)
                    this.getData()
                }else{
                    this.$message.error(res.data.Message)
                }
            }).catch(() => {
                this.dialogLoading = false
                this.dialogVisible = false
            })
        },
        editSubmit(params){
            params.id = this.currentRow.ID
            updateExamProjectInfo(params).then(res => {
                this.dialogLoading = false
                this.dialogVisible = false
                if(res.data.Success){
                    this.$message.success(res.data.Message)
                    this.getData()
                }else{
                    this.$message.error(res.data.Message)
                }
            }).catch(() => {
                this.dialogLoading = false
                this.dialogVisible = false
            })
        },
        // 对话框关闭清空数据
        dialogClose(){
            this.$refs['formRef'].resetFields();               
        },
        // 得到考试试卷
        getExamPaper(){
            var params = {
                isEnable:true
            }
            getExaminationPaperListPage(params).then(res => {
                if(res.data.Success){
                    var data = res.data.Response.Data
                    this.options1 = data.map(item => {
                        if(item.IsEnable){
                            return ({
                                label:item.PaperName,
                                value:item.ID
                            })
                        }
                    })
                    this.pagesForExamPaper.dataCount = this.options1.length
                }else{
                    this.options1 = []
                    this.pagesForExamPaper.dataCount = 0
                }
            }).catch(() => {})
        },
        // 试卷分页
        handleCurrentChangeForExamPaper(value){
            this.pagesForExamPaper.pageIndex = value
            this.getExamPaper()
        },
        // 得到审批流程
        getApprovalProcess(){
            var params = {
                isEnable:true
            }
            getApprovalProcessListPage(params).then(res => {
                if(res.data.Success){
                    var data = res.data.Response.Data
                    this.options2 = data.map(item => {
                        if(item.IsEnable){
                            return ({
                                label:item.ProcessName,
                                value:item.ID
                            })
                        }
                    })
                    this.pagesForApprovalProcess.dataCount = this.options2.length
                }else{
                    this.options2 = []
                    this.pagesForApprovalProcess.dataCount = 0
                }
            }).catch(() => {})
        },
    },
    beforeRouteEnter(to,from,next){
        if(from.path.indexOf(to.path) == -1){
            window.sessionStorage.removeItem('pagination')
        }
        next()
    },
    created(){
        this.pages.pageIndex = Number(window.sessionStorage.getItem('pagination')) || 1
        this.getData()
    },
    mounted(){
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
}
</script>

<style lang="stylus" scoped>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>
<style>
    .el-tooltip__popper {
        max-width: 500px;
    }
</style>